#wagerindexitem-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#wagerindexitem-outcome {
    display: flex;
    justify-content: space-between;
}

#wagerindexitem-money {
    display: flex;
    justify-content: space-between;
}

#wagerindexitem-participants {
    display: flex;
    justify-content: space-between;
}

#wagerindexitem-acceptbutton {
    padding: 10px;
    border-radius: 5px;
    background-color: rgba(100, 100, 100, 0.783);
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
}

#wagerindexitem-acceptbutton:hover {
    background-color: rgb(62, 119, 81);
}