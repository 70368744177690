#wagermodal-container {
    position: fixed;
    /* // comment in to add black background 
    height: 100%;
    width: 100%; */
    top: calc(50vh - 250px);
    right: calc(50vw - 500px);
    background-color: rgba(0, 0, 0);
    z-index: 10;
    overflow-x: hidden;
    border-radius: 20px;
}

#wagermodal-createwagercontainer {
    width: 1000px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    background-color: rgb(18, 18, 18);
    border: 1px solid gray;
    border-radius: 20px;
    padding: 20px;
    color: white;
}

#wagermodal-marketcontainer {
    margin: 20px;
    padding: 50px;
    padding-top: 25px;
    background-color: black;
    border-radius: 20px;
}

#wagermodal-marketheader {
    width: 230px;
    display: flex;
    justify-content: space-around;
    margin: 10px;
    font-size: 12px;
}

#wagermodal-orderbook {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
}

#wagermodal-teamonebids {
    display: flex;
    flex-direction: column; 
    align-items: center;
}

#wagermodal-teamtwobids {
    display: flex;
    flex-direction: column; 
    align-items: center;
    
}

#wagermodal-teamheader {
    font-size: 20px;
    display: flex;
    justify-content: center;
}

#wagermodal-orderitemteamone {
    width: 230px;
    display: flex;
    justify-content: space-around;
    background-color: rgba(163, 163, 163, 0.166);
    border-radius: 3px;
    padding: 5px;
    margin-bottom: 7px;
    cursor: pointer;
}

#wagermodal-orderitemteamone:hover {
    background-color: rgba(11, 179, 67, 0.69);
}

#wagermodal-orderitemteamtwo {
    width: 230px;
    display: flex;
    justify-content: space-around;
    background-color: rgba(163, 163, 163, 0.166);
    border-radius: 3px;
    padding: 5px;
    margin-bottom: 7px;
    cursor: pointer;
}

#wagermodal-orderitemteamtwo:hover {
    background-color: rgba(11, 179, 67, 0.69);
}

#wagermodal-rightside {
    width: 300px;
}

#wagermodal-closebutton {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    font-size: 50px;
}

#wagermodal-createwagerform {
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 40px;
    padding-top: 0px;
    padding-left: 0px;
}

#wagermodal-createwagerform > * {
    margin-top: 30px;
}

#wagermodal-createwagerform > *:first-child {
    margin-top: 0;
}

#wagermodal-wagersummary {
    font-size: 14px;
    height: 20px;
    display: flex;
    justify-content: space-between;
}

#wagermodal-createwagerform input {
    height: 45px;
    padding-left: 15px;
    padding-right: 10px;
    border-radius: 7px;
    border: none;
    background-color: rgba(46, 46, 46, 0.832);
    color: white;
}

#wagermodal-createwagerform input:focus {
    outline: none;
    border: 0.5px solid rgba(30, 193, 87, 0.671);
}

#wagermodal-createwagerform input:hover {
    background-color: rgba(65, 65, 65, 0.804);
}

#wagermodal-selectoutcome {
    display: flex;
    justify-content: space-evenly;
}

.wagermodal-outcomebutton {
    border-radius: 7px;
    border: none;
    height: 40px;
    width: 110px;
    background-color: rgba(46, 46, 46, 0.832);
    color: white;
}

.wagermodal-outcomebutton:hover {
    cursor: pointer;
    background-color: rgba(65, 65, 65, 0.804);
}

#selectedoutcome {
    background-color: whitesmoke;
    color: black;
}

#wagermodal-createwagerbutton {
    cursor: pointer;
    height: 40px;
    border-radius: 10px;
    border: none;
    background-color: rgba(100, 100, 100, 0.783);
    color: white;
}

#wagermodal-createwagerbutton:hover {
    background-color: rgba(11, 179, 67, 0.752);
}

#wagermodal-cancelwagerbutton {
    cursor: pointer;
    height: 40px;
    border-radius: 10px;
    border: none;
    background-color: rgba(100, 100, 100, 0.783);
    color: white;
}

#wagermodal-cancelwagerbutton:hover {
    background-color: rgba(192, 37, 57, 0.727);
}


