#homepage-outtercontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #121212, #191919, #121212);
    height: 100vh;
}

#homepage-gameindex {
    margin-top: 100px;
    margin-left: 100px;
    margin-right: 100px;
    width: 1300px;
    padding: 30px;
    background-color: rgb(38, 38, 38);
    border-radius: 20px;
    overflow: auto;
}

#homepage-wagerindex {
    margin-top: 30px;
    margin-bottom: 40px;
    margin-left: 100px;
    margin-right: 100px;
    width: 1300px;
    height: 500px;
    padding: 30px;
    background-color: rgb(38, 38, 38);
    border-radius: 20px;
    overflow: auto;
}

