#userprofile-outtercontainer {
    background: linear-gradient(to right, #29323c, #485563);
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

#userprofile-activewagerindex {
    width: 1300px;
    padding: 30px;
    background-color: rgba(4, 38, 98, 0.392);
    border-radius: 20px;
    overflow: auto;
}

#userprofile-pastwagerindex {
    width: 1300px;
    padding: 30px;
    background-color: rgba(4, 38, 98, 0.392);
    border-radius: 20px;
    overflow: auto;
}