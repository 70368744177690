#wagerindex-outtercontainer {
    font-family: 'Poppins', sans-serif;
}

#wagerindex-header {
    display: flex;
    font-size: 30px;
    margin: 15px;
    color: white;
}

#wagerindex-createwager {
    cursor: pointer;
    margin-left: 40px;
    font-family: 'Poppins', sans-serif;
    color: white;
    background-color: rgba(100, 100, 100, 0.783);
    border: none;
    border-radius: 5px;
    padding: 10px;
}

#wagerindex-createwager:hover {
    background-color: rgb(62, 119, 81);
}

#wagerindex-container {
    display: flex;
    flex-wrap: wrap;
}

#wagerindexitem {
    height: 200px;
    width: calc(33.33% - 20px);
    margin: 10px;
    box-sizing: border-box;
    background-color: rgb(20, 20, 20);
    border: 0.5px solid rgb(92, 92, 92);
    border-radius: 20px;
    padding: 20px;
    color: white;
}

#wagerindexitem > input {
    margin: 15px;
    border-radius: 5px;
}

#wagerindexitem button {
    margin: 15px;
    border-radius: 5px;
    cursor: pointer;
}

#wagerindexitem > div {
    margin-bottom: 5px;
}