#gameindexitem-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}

#gameindexitem-top {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-top: -10px;
}

#gameindexitem-starttime {
    font-size: 11px;
}

#gameindexitem-matchup {
    display: flex;
    justify-content: space-around;
    font-size: 22px;
    padding-bottom: 5px;
    border-bottom: 0.8px solid rgb(255, 255, 255);
}


#gameindexitem-atsign {
    font-weight: 550;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    margin-top: 5px;
}

#gameindexitem-moneylines {
    display: flex;
    justify-content: space-around;
    font-size: 20px;
    margin-top: 15px;
}

#gameindexitem-mlheader {
    font-weight: 550;
    font-size: 14px;
    margin-top: 13px;
}

#gameindexitem-spreads {
    display: flex;
    justify-content: space-around;
    font-size: 20px;
}

#gameindexitem-spreadheader {
    font-weight: 550;
    font-size: 14px;
    margin-top: 13px;
}

#gameindexitem-betoption {
    padding: 10px;
    cursor: pointer;
}

#gameindexitem-betoption:hover {
    cursor: pointer;
    padding: 10px;
    background-color: white;
    color: black;
    font-weight: 800;
    border-radius: 5px;
}