#gameindex-outtercontainer {
    font-family: 'Poppins', sans-serif;
}

#gameindex-header {
    font-size: 30px;
    margin: 15px;
    color: white;
}

#gameindex-container {
    display: flex;
    flex-wrap: wrap;
}

#gameindexitem {
    height: 200px;
    width: calc(33.33% - 20px);
    margin: 10px;
    box-sizing: border-box;
    background-color: rgb(20, 20, 20);
    border: 0.5px solid rgb(92, 92, 92);
    border-radius: 20px;
    padding: 25px;
    color: white;
}
