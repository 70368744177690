#navbar-outtercontainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    height: 100px;
    justify-content: space-around;
    align-items: center;
}

#navbar-leftside {
    font-size: 30px;
    display: flex;
}

#navbar-userbalance {
    margin-left: 30px;
    display: flex;
}

#navbar-userbalancevalue {
    font-size: 30px;
    margin-left: 30px;
    color: white;
    font-family: 'Poppins', sans-serif;
}

#navbar-mintbutton {
    width: 150px;
    margin-right: 30px;
    cursor: pointer;
    height: 40px;
    border-radius: 10px;
    border: none;
    background-color: rgba(100, 100, 100, 0.783);
    color: white;
}

#navbar-mintbutton:hover {
    background-color: rgba(11, 179, 67, 0.752);
}

#navbar-middle {
    font-size: 40px;
    position: relative;
    /* left: -35px; */
    font-family: 'Poppins', sans-serif;
}

#navbar-leftside i,
#navbar-middle a{
    color: white;
    text-decoration: none;
}

#navbar-wallet {
    display: flex;
    font-size: 30px;
    color: white;
    cursor: pointer;
}

#navbar-connectedheader {
    font-size: 20px;
    color: white;
    font-family: 'Poppins', sans-serif;
    margin-right: 20px;
    margin-top: 5px;
}

